@import '@primer/css/utilities/index.scss';
@import '@primer/css/layout/index.scss';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&display=swap');
@import 'react-toastify/dist/ReactToastify.css';

@import 'assets/scss/colors.scss';
@import 'assets/scss/typography.scss';
@import 'assets/scss/forms.scss';
@import 'assets/scss/buttons.scss';
@import 'assets/scss/loader.scss';
@import 'assets/scss/tooltip.scss';

@import 'assets/scss/authloader.scss';
@import 'react-datepicker/dist/react-datepicker.css';

@import 'assets/scss/authloader.scss';
@import 'assets/scss/reportview.scss';
@import 'assets/scss/inbox.scss';
@import 'assets/scss//avatarColors.scss';
@import 'assets/scss/batchprocessing.scss';

body,
html {
  margin: 0;
  padding: 0;
  border: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $gray-color-2;
}

div {
  box-sizing: border-box;
}

input,
select,
textarea,
button {
  font-family: 'Inter', sans-serif;
}

.app-container {
  height: 100vh;
}

.sidenav {
  width: 280px;
  border-right: 1px solid rgba(194, 197, 195, 0.4);
  .logo {
    img {
      width: 100px;
    }
  }
  .nav-link {
    color: rgba(23, 43, 77, 0.6);
    padding: 10px 0;
    border-radius: 4px;

    &.active,
    &:hover {
      color: $primary-color !important;

      svg {
        color: $black !important;
      }
    }

    &.active {
      background: rgba(218, 220, 234, 0.4) !important;
    }

    &:hover {
      background: rgba(218, 220, 234, 0.2);
    }
  }
}
.main-area {
  padding-top: 51px;
}
.scrollable-page {
  height: calc(100vh - 50px);
  overflow: auto;
}

.topnav {
  height: 50px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.info-table {
  .info-head {
    min-width: 140px;
  }
}

.divider {
  width: 2px;
  background-color: #ddd;
}

//datepicker override
.react-datepicker__close-icon::after {
  background-color: transparent;
  color: #020202;
  height: auto;
  width: auto;
  padding: 0;
  font-size: 22px;
  position: relative;
  top: -2px;
}
.react-datepicker__header {
  background-color: transparent;
  background: rgb(234, 247, 245);
  background: linear-gradient(
    90deg,
    rgba(234, 247, 245, 1) 35%,
    rgba(247, 238, 247, 1) 100%
  );
}
.react-datepicker__header__dropdown {
  &.react-datepicker__header__dropdown--select {
    margin-top: 10px;
  }
  .react-datepicker__month-select,
  .react-datepicker__year-select {
    padding: 4px;
  }
}

//modal
.modal-overlay {
  background: rgba(20, 20, 20, 0.25);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  z-index: 11;
}
.modal {
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  width: 550px;
  background: #ffffff;
  box-shadow: 0px 4px 22px 14px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  position: absolute;
  overflow: auto;
  max-height: 80vh;

  .modal-close-btn {
    position: absolute;
    top: 0;
    right: 0;
    padding: 8px;
    cursor: pointer;
    font-size: 26px;
    color: #675e5e;
  }
  .modal-input-wrapper {
    width: 345px;
    display: inline-flex;
    flex-direction: column;
  }
}

.pagination {
  list-style: none;
  list-style-type: none;
  border: 1px solid rgba(0, 0, 0, 0.2);
  li {
    display: inline-block;
    font-size: 16px;
    line-height: 1;
    background-color: $white;
    color: $text-primary-color;
    text-align: center;
    cursor: pointer;
    a {
      padding: 12px;
      min-width: 15px;
      display: block;
    }
    &:not(:last-child) {
      border-right: 1px solid rgba(0, 0, 0, 0.2);
    }
    &.pagination_prev-btn,
    &.pagination_next-btn {
      a {
        padding: 12px 16px;
      }
    }
    &.active {
      background-color: $primary-color;
      color: $white;
    }
    &:hover:not(.active) {
      background-color: $gray-color-1;
    }
  }
}

/* table */
.table {
  width: 100%;
  border-collapse: collapse;
  tbody {
    tr {
      border-bottom: 0.5px solid #cbcfcb;
    }
    tr:last-child {
      border-bottom: none;
    }
  }
  th,
  td {
    padding: 25px 30px;
    text-align: left;
  }
}

.indicator {
  position: absolute;
  top: 0px;
  left: 0px;
  padding: 10px;
}

/* Filter modal*/
.filter-btn {
  z-index: 112;
}

.active-filter-btn{
  z-index: 115;
}

.filter-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
  width: 100vw;
  height: 100vh;
  z-index: 114;
}

.filter-modal {
  right: 0;
  top: 37px;
  width: 300px;
  text-align: left;
  z-index: 11;
}

.filter-modal-2 {
  top: 40px;
  left: 1px;
  right: 155px;
  width: auto;
  z-index: 115;
}

.filter-tag {
  background: rgba(170, 187, 224, 0.3);
  padding: 6px 12px;
  display: inline-flex;
  align-items: center;
  margin: 0 2px;
  cursor: pointer;
}

.filter-remove-cross {
  cursor: pointer;
  padding: 0 3px;
  transform-origin: center;
  transition: all 0.3s ease;
  &:hover {
    color: #000 !important;
    transform: scale(1.3);
  }
}

.failed-report {
  background-color: #f1eded !important;
  border: 1px solid #f00;
}

.above-overlay {
  z-index: 11;
  position: relative;
}

.bell-icon {
  &:after {
    content: '';
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 10px solid #fff;
    position: absolute;
    top: 29px;
    right: 18px;
    z-index: 11;
  }
}

.user-name {
  max-width: 350px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.notification-modal {
  width: 400px;
  height: 450px;
  background: #fff;
  position: absolute;
  top: 50px;
  right: 32px;
  border-radius: 4px;
  z-index: 113;


  .notification-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }
  .notification-body {
    overflow: hidden;
    height: calc(100% - 85px);
    .infinite-scroll-component__outerdiv {
      height: calc(350px);
    }
  }

  .single-notifiaction {
    width: 100% !important;
    padding-bottom: 16px;
    cursor: pointer;
    &:not(:first-child) {
      padding-top: 16px;
    }
    &:not(:last-child) {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
    & > div {
      width: 100% !important;
    }
    .text-ellipsis {
      width: calc(100%) !important;
    }
  }
}

.active-noti-dot {
  position: absolute;
  background: #5a98f2;
  border: 2px solid #ffffff;
  width: 6px;
  height: 6px;
  top: 1px;
  left: 11px;
  z-index: 11;
  border-radius: 100%;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-no-drop {
  cursor: no-drop !important;
}

.child-tags-with-no-margin {
  max-height: 18px;
  * {
    margin: 0 !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    display: inline !important;
  }
  ul {
    padding: 10px;
  }
}

.align-middle {
  vertical-align: middle;
}

.copy:hover {
  color: $text-secondary-color;
}