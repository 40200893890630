.avatar_bg_A {
  background: #605458 !important;
}

.avatar_bg_B {
  background: #4e3140 !important;
}

.avatar_bg_C {
  background: #3a3b52 !important;
}

.avatar_bg_D {
  background: #1f5645 !important;
}

.avatar_bg_E {
  background: #5d8d2c !important;
}

.avatar_bg_F {
  background: #7c0d0d !important;
}

.avatar_bg_G {
  background: #705e39 !important;
}

.avatar_bg_H {
  background: #463087 !important;
}

.avatar_bg_I {
  background: #9f1e99 !important;
}

.avatar_bg_J {
  background: #cd164f !important;
}

.avatar_bg_K {
  background: #ef7910 !important;
}

.avatar_bg_L {
  background: #7d895c !important;
}

.avatar_bg_M {
  background: #4fb180 !important;
}

.avatar_bg_N {
  background: #099dd9 !important;
}

.avatar_bg_O {
  background: #80f !important;
}

.avatar_bg_P {
  background: #057845 !important;
}

.avatar_bg_Q {
  background: #000 !important;
}

.avatar_bg_R {
  background: #5c4c3d !important;
}

.avatar_bg_S {
  background: #4f3578 !important;
}

.avatar_bg_T {
  background: #3f5241 !important;
}

.avatar_bg_U {
  background: #8589ad !important;
}

.avatar_bg_V {
  background: #ad0b0b !important;
}

.avatar_bg_W {
  background: #40d189 !important;
}

.avatar_bg_X {
  background: #b55489 !important;
}

.avatar_bg_Y {
  background: #8d8a08 !important;
}

.avatar_bg_Z {
  background: #0db5a7 !important;
}
