.csv-render-table {
    margin-top: 9px;
    // border-top: 7px solid rgb(116, 154, 240);
    border-radius: 9px;
    border-collapse: separate !important;
    tr {
        border: 1px solid grey;
        td, th {
            background-color: #ffffff;
            border: 1px solid #e8eae8;
            border-collapse: collapse;
            padding: 10px;
        }

    }
}

.uploaded-file{
    padding-left: 19px;
    font-size: 13px;
}

.tbl-tilte {
    padding-left: 20px;
    margin-top: 12px;
}

.highlighted-td, .highlighted-th {
    background-color: #f5f5f5 !important;
}

.batch-processing-header {
    padding: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}
.upload-csv-section {
    padding-left: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}  

.attach-button {
    margin-left: 250px;
    margin-bottom: 25px;
    padding: 10px 20px;
    color: #5a98f2;
    border: 1px solid #5a98f2;
    border-radius: 5px;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .attach-button:hover {
    background-color: lightblue;
  }
  
  .batch-submit-btn {
    margin-right: 30px;
    padding: 10px 20px;
    float: right;
    transition: background-color 0.3s ease;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
    background-color: #1a2e5a;
    border: none;
    border-radius: 4px;
    color: #fff;
  }
  
  .batch-submit-btn:hover {
    background-color: lightblue;
  }
  .table-section {
    padding-left: 20px;
  }

  .patient-details-tbl-title {
    font-size: 13px;
    padding-left: 544px;
    font-weight: bold;
  }