.tooltip {
  position: relative;
  display: inline-block;
}
  
/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 200px;
  background-color: $primary-color;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  font-size: smaller;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
  bottom: 110%;
  left: 50%;
  margin-left: -100px; 
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}