@mixin button {
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
}

// primary button
.btn-primary {
  @include button;
  background-color: $primary-color;

  border: none;
  border-radius: 4px;
  color: $white;

  &:hover {
    background-color: $primary-dark-color;
  }
  &:active {
    box-shadow: 0px 0px 2px 3px rgba(51, 85, 158, 0.5);
  }
}

// disabled button
.btn-disabled {
  @include button;
  background-color: $text-secondary-color;
  border: none;
  border-radius: 4px;
  color: $white;
  cursor: default;
  &:hover {
    background-color: $text-secondary-color;
  }
}

// secondary button
.btn-secondary {
  @include button;
  background-color: $white;
  border: 1px solid $secondary-color;
  border-radius: 4px;
  color: $secondary-color;

  &:hover {
    background-color: #eef4fd;
  }
  &:active {
    border: 1px solid transparent;
    box-shadow: 0px 0px 2px 3px rgba(90, 152, 242, 0.2);
  }
}

//primary link
.link {
  @include button;
  color: $secondary-color;
  background: none;
  border: none;
}

//secondary link
.secondary-link {
  @include button;
  background-color: #f0f1f7;

  border: none;
  border-radius: 4px;
  color: $secondary-color;

  &:hover {
    background-color: #e1e3ee;
  }
}

.btn-in-progress {
  @include button;
  background-color: $secondary-color;
  border: none;
  border-radius: 4px;
  color: $white;
  overflow: hidden;

  .progress-track {
    background-color: $secondary-light-color;
    width: 100%;
    height: 6px;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .progress-indicator {
    background-color: $secondary-dark-color;
    height: 100%;
    background: linear-gradient(274deg, $secondary-dark-color, $black);
    background-size: 400% 400%;

    -webkit-animation: AnimationName 0.5s ease infinite;
    -moz-animation: AnimationName 0.5s ease infinite;
    animation: AnimationName 0.5s ease infinite;
  }
  @-webkit-keyframes AnimationName {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  @-moz-keyframes AnimationName {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  @keyframes AnimationName {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
}

.btn-actions {
  @include button;
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  color: $text-secondary-color;

  &:hover {
    background-color: $white;
  }
  &:active {
    box-shadow: 0px 0px 2px 3px rgba(0, 0, 0, 0.2);
  }
  &:focus,
  &:focus-visible {
    outline: none;
  }
}

.btn-search {
  @include button;
  background-color: $white;
  border: 1px solid rgba(118, 120, 133, 0.5);
  border-left: 0;
  border-radius: 0px 4px 4px 0px;

  &:focus,
  &:focus-visible {
    outline: none;
  }
}

.bg-round-faded {
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    background: rgba(255, 255, 255, 0.15);
    padding: 5px;
    width: 14px;
    height: 14px;
    border-radius: 3px;
    margin-right: 8px;
  }
}

.btn-round-back {
  background-color: $gray-color-1;
  width: 22px;
  height: 22px;
  padding: 9px;
  border-radius: 100%;
  cursor: pointer;

  &:hover {
    background-color: #cccedd;
  }
}

.bg-transparent {
  background-color: transparent !important;
  background: transparent !important;
}
