.view-report-page {
  .container {
    padding: 16px;
  }
  .block {
    padding: 16px;
    border: 1px solid #ddd;
    display: flex;
    flex-wrap: wrap;
    background: #ffffff;
  }
  .data-row {
    margin: 8px 16px;
  }
  .test-data{
    width: 100%;
  }
  .data-head {
    margin: 0 0 4px 0;
    font-size: 12px;
    color: #8b8494;
    font-weight: 600;
  }
  .data-info {
    margin: 0;
    font-size: 14px;
    color: #2f2f2f;
  }
  .divider {
    width: 100%;
    margin: 8px 0;
  }
  .data-date {
    font-size: 12px;
    color: #fff;
    padding: 6px 10px;
    background: #49a7ce;
    border-radius: 4px;
    line-height: 1;
    margin: 2px 2px 4px 0;
    display: inline-block;
  }
  .data-info-btn {
    margin: 0;
    color: #181582;
    text-decoration: underline;
  }
  .border {
    border: 1px solid #6d6969 !important;
  }
  .border-t {
    border-top: 1px solid #6d6969;
  }
  .border-b {
    border-bottom: 1px solid #6d6969;
  }
  .border-l {
    border-left: 1px solid #6d6969;
  }
  .border-r {
    border-right: 1px solid #6d6969;
  }
  .p-4 {
    padding: 4px;
  }
  .p-8 {
    padding: 8px;
  }
  .table {
    border-collapse: collapse;
    margin: 0;
    border-spacing: 0;
    outline: 0;
    text-align: left;
  }
  .table th,
  .table td {
    border-bottom: 1px solid #6d6969;
  }
  .data-link {
    margin: 0 0 4px 0;
    color: #181582;
    text-decoration: underline;
    display: block;
    cursor: pointer;
  }
  .fetched_data {
    white-space: pre-wrap; /* Since CSS 2.1 */
    white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
    white-space: -pre-wrap; /* Opera 4-6 */
    white-space: -o-pre-wrap; /* Opera 7 */
    word-wrap: break-word; /* Internet Explorer 5.5+ */
  }
  @media print {
    .pagebreak {
      clear: both;
      page-break-after: always;
    }
  }
  @page {
    margin: 0;
    padding: 0;
    size: A4;
  }
  .page {
    page-break-after: always;
    min-height: 915px;
  }
  @media print {
    div.printBlock {
      page-break-inside: avoid;
      break-inside: avoid;
    }
  }
}

.lab-text {
  width: 50%;
}

.lab-value {
  width: 50px;
}