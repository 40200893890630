h1,
.h1 {
  font-size: 36px !important;
  font-weight: 600 !important;
}

h2,
.h2 {
  font-size: 32px !important;
  font-weight: 600 !important;
}

h3,
.h3 {
  font-size: 28px !important;
  font-weight: 600 !important;
}

h4,
.h4 {
  font-size: 24px !important;
  font-weight: 600 !important;
}

h5,
.h5 {
  font-size: 20px !important;
  font-weight: 600 !important;
}

h6,
.h6 {
  font-size: 18px !important;
  font-weight: 600 !important;
}

p,
.p {
  font-size: 16px !important;
  font-weight: 400 !important;
}

.small {
  font-size: 14px !important;
  font-weight: 400 !important;
}

.x-small {
  font-size: 12px !important;
}

.xx-small {
  font-size: 11px !important;
}

.fs-regular {
  font-size: 16px !important;
}

.fs-medium {
  font-size: 18px !important;
}

.fw-light {
  font-weight: 300 !important;
}
.fw-regular {
  font-weight: 400 !important;
}
.fw-medium {
  font-weight: 500 !important;
}
.fw-semibold {
  font-weight: 600 !important;
}
.text-capitalize {
  text-transform: capitalize;
}

.clean-link:hover {
  color: $primary-light-color !important;
}