.tab-wrapper {
  border-bottom: 2px solid rgba(26, 46, 90, 0.1);
  .tab-item {
    cursor: pointer;
    position: relative;
    bottom: -2px;
    border-bottom: 2px solid transparent;
    &.active {
      border-bottom: 2px solid $text-primary-color;
    }
  }
}

.inbox-wrapper {
  width: 100%;
  margin: 16px 0 0;
  height: calc(100vh - 190px);
  overflow: hidden;

  .infinite-scroll-component__outerdiv {
    height: calc(100vh - 190px);
  }

  .inbox-message {
    cursor: pointer;
    height: 80px;
    background: rgba(255, 255, 255, 0.35);
    border-left: 4px solid transparent;
    border-bottom: 2px solid rgba(26, 46, 90, 0.1);
    &:hover {
      border-left: 4px solid $gray-color-1;
    }
    &.unread {
      background: $white !important;
      border-left: 4px solid $highlight-color;
    }
  }
}

.avatar-initials {
  width: 60px;
  height: 60px;
  background: #1b3493;
  border-radius: 30px;
  margin-top: 10px;
}

.text-ellipsis {
  overflow: auto;
  width: calc(100vw - 456px);
  p {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    width: 100%;
  }
}

.rte-editor {
  border: 1px solid rgba(118, 120, 133, 0.5) !important;
  box-sizing: border-box;
  border-radius: 4px !important;
  font-family: 'Inter', sans-serif !important;
  position: relative;
  z-index: 1;

  .public-DraftEditor-content {
    min-height: 240px;
  }
  .DraftEditor-editorContainer {
    z-index: 0;
  }
}

.msg-divider:not(:last-child) {
  border-bottom: 2px solid rgba(26, 46, 90, 0.1);
}

.new-tag {
  font-size: 12px;
  font-weight: 500;
  background: #355ff3;
  color: #fff;
  padding: 2px 6px;
  border-radius: 11px;
}

.thread-detail-wrapper {
  height: calc(100vh - 273px);
  overflow: hidden;

  .infinite-scroll-component__outerdiv {
    height: calc(100vh - 273px);
  }
}

.thread-message {
  white-space: pre-wrap;
  line-height: 1.3 !important;
  padding-top: 4px;
  padding-bottom: 2px;
  text-align: justify;

  * {
    margin: 0 !important;
    white-space: normal;
    line-height: 1.5 !important;
  }
  p {
    font-size: 14px !important;
    font-weight: 400 !important;
  }
}

.launcher {
  bottom: 0;
  margin: 20px;
  position: fixed;
  right: 0;
}

.reply-box {
  z-index: 1000;
  position: fixed;
  bottom: 24px;
  right: 24px;
  min-width: 462px;
  min-height: 500px;
  display: flex;
  flex-direction: row;
  max-width: 87vw;
  max-height: 92vh;
  word-break: break-word;

  .reply-form-wrapper {
    flex: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .reply-close {
    color: rgba(0, 0, 0, 0.5);
    position: absolute;
    right: -8px;
    top: -8px;
    display: flex;
    border-radius: 100%;
    background: #fff;
    height: 28px;
    border: 2px solid rgba(0, 0, 0, 0.5);
    width: 28px;
    align-items: center;
    justify-content: center;
    padding: 4px;
    z-index: 1001;
    svg {
      cursor: inherit;
    }
  }
  .reply-rte-editor {
    font-family: 'Inter', sans-serif !important;
    height: 100%;

    .public-DraftEditor-content {
      min-height: 250px;
    }
  }
  .drag-button {
    position: absolute;
    background: none;
    border: none;
    top: 1px;
    left: -1px;
    width: 18px;
    height: 18px;
    overflow: hidden;
    padding: 0;
    margin: 0;
    line-height: 1;
    outline: none;
    svg {
      transform: rotate(135deg);
      color: rgba(0, 0, 0, 0.5);
    }
  }
}

.width-100p {
  width: 100%;
}

.height-100p {
  height: 100%;
}

.richtext_wrapper {
  max-height: calc(100% - 80px);
  & > div {
    .reply-rte-editor {
      overflow: hidden;
    }
    overflow: hidden;
    .RichTextEditor__editor___1QqIU {
      height: 100%;
      .DraftEditor-root {
        overflow: scroll;
        max-height: calc(100% - 51px);
      }
    }
  }
}

.adv-search-option {
  position: absolute;
  top: 0px;
  right: 0px;
  color: rgba(0, 0, 0, 0.7);
  font-size: 26px;
  padding: 7px 12px 7px 7px;
  cursor: pointer;
}

.autosearch-result-wrapper {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #172b4d;
}
.autosearch-result-row-small {
  font-size: 11px;
}

.collapsed:hover {
  background: #dadcea;
}