.reset-focus-style {
  &:focus,
  &:focus-within {
    box-shadow: initial !important;
    border-color: #a9c8f5 !important;
  }
}

.reset-hover-style {
  border-color: rgba(118, 120, 133, 0.5) !important;
  &:hover:not(&:focus-within) {
    border-color: rgba(118, 120, 133, 0.5) !important;
  }
}

.form-input {
  height: 40px;
  border: 1px solid rgba(118, 120, 133, 0.5);
  box-sizing: border-box;
  border-radius: 4px !important;
  padding: 0 16px;
  font-size: 16px;

  &:focus,
  &:focus-within {
    border: 1px solid #a9c8f5;
    outline: none;
    box-shadow: none;
  }
  &.disabled {
    background: #f3f5fb;
    border: 1px solid #c2c4c3;
  }
  &.search-icon {
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
}

.checkbox-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    border: 1px solid #767885;
  }

  input:checked ~ .checkmark {
    background-color: $secondary-color;
    border: 1px solid $secondary-color;
  }

  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  input:checked ~ .checkmark:after {
    display: block;
  }

  .checkmark:after {
    left: 8px;
    top: 3px;
    width: 6px;
    height: 12px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

.radio-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    background-color: $white;
    border-radius: 50%;
    border: 1px solid $text-secondary-color;
  }

  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  input:checked ~ .checkmark:after {
    display: block;
  }

  .checkmark:after {
    top: 5px;
    left: 5px;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: $secondary-color;
  }
}

.card {
  box-shadow: 4px 4px 18px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}

.input-sm {
  width: 80px !important;
}

.input-md {
  width: 200px !important;
}
.input-lg {
  width: 424px !important;
}
.input-auto {
  width: 100% !important;
}
