$white: #fff;
$black: #000;

$primary-color: #1a2e5a;
$secondary-color: #5a98f2;
$highlight-color: #355ff3;

$text-primary-color: #172b4d;
$text-secondary-color: #767885;
$text-light-color: #c2c5c3;

$success-color: #73bc16;
$warning-color: #e5b50a;
$error-color: #eb3f27;
$info-color: #4db8e7;

$primary-dark-color: #33559e;
$primary-light-color: #aabbe0;

$secondary-dark-color: #0a5cd2;
$secondary-light-color: #a9c8f5;

$gray-color-1: #dadcea;
$gray-color-2: #f3f5fb;

//color classes
.color-white {
  color: $white !important;
}
.color-black {
  color: $black !important;
}
.color-primary {
  color: $primary-color !important;
}
.color-secondary {
  color: $secondary-color !important;
}
.color-highlight {
  color: $highlight-color !important;
}
.color-text-primary {
  color: $text-primary-color !important;
}
.color-text-secondary {
  color: $text-secondary-color !important;
}
.color-text-light {
  color: $text-light-color !important;
}
.color-success {
  color: $success-color !important;
}
.color-warning {
  color: $warning-color !important;
}
.color-error {
  color: $error-color !important;
}
.color-info {
  color: $info-color !important;
}
.color-primary-dark {
  color: $primary-dark-color !important;
}
.color-primary-light {
  color: $primary-light-color !important;
}
.color-secondary-dark {
  color: $secondary-dark-color !important;
}
.color-secondary-light {
  color: $secondary-light-color !important;
}
.color-gray-1 {
  color: $gray-color-1 !important;
}
.color-gray-2 {
  color: $gray-color-2 !important;
}

//background-color classes
.bg-white {
  background-color: $white !important;
}
.bg-black {
  background-color: $black !important;
}
.bg-primary {
  background-color: $primary-color !important;
}
.bg-secondary {
  background-color: $secondary-color !important;
}
.bg-highlight {
  background-color: $highlight-color !important;
}
.bg-text-primary {
  background-color: $text-primary-color !important;
}
.bg-text-secondary {
  background-color: $text-secondary-color !important;
}
.bg-text-light {
  background-color: $text-light-color !important;
}
.bg-success {
  background-color: $success-color !important;
}
.bg-warning {
  background-color: $warning-color !important;
}
.bg-error {
  background-color: $error-color !important;
}
.bg-info {
  background-color: $info-color !important;
}
.bg-primary-dark {
  background-color: $primary-dark-color !important;
}
.bg-primary-light {
  background-color: $primary-light-color !important;
}
.bg-secondary-dark {
  background-color: $secondary-dark-color !important;
}
.bg-secondary-light {
  background-color: $secondary-light-color !important;
}
.bg-gray-1 {
  background-color: $gray-color-1 !important;
}
.bg-gray-2 {
  background-color: $gray-color-2 !important;
}

//border-color classes
.border-error {
  border-color: $error-color !important;
  &.form-input:not(:focus-within) {
    ::placeholder {
      color: $error-color;
      opacity: 1;
    }

    :-ms-input-placeholder {
      color: $error-color;
    }

    ::-ms-input-placeholder {
      color: $error-color;
    }
  }
}
.border-success {
  border-color: $success-color !important;
}
.border-warning {
  border-color: $warning-color !important;
}
.border-info {
  border-color: $info-color !important;
}
